import React,{useEffect, useState} from "react";
import DataTable from "react-data-table-component";

const Confrim =()=>{
    const [country,setcountry]=useState([])

    useEffect(()=>{
        fetchApi()
    },[])
   const fetchApi=()=>{
    fetch("https://jsonplaceholder.typicode.com/posts")
    .then((res)=>{
        res.json().then((data)=>{
            setcountry(data)
        })
    })
   }

   const columns=[
    {
        name:"user",
        selector:(row)=>row.userId
    },
    {
        name:"title",
        selector:(row)=>row.title
    },
    {
        name:"body",
        selector:(row)=>row.body
    }
   ]
    return(
        <>
        <DataTable columns={columns} data={country} pagination fixedHeaderScrollHeight="400px" />
        </>
    )
}
export default Confrim