import React, { useState, useEffect, useRef } from "react";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import moment from "moment";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

const InterCity = () => {
  const today = dayjs();
  const [time, settime] = useState(today);
  const [drop, setdrop] = useState("");
  const [dropValue, setDropValue] = useState("");
  const [address, setaddress] = useState("");
  const [addressValue, setAddressValue] = useState("");
  const dropAutocompleteRef = useRef(null);
  const autocompleteRef = useRef(null);
  const [pickupAddress, setPickupAddress] = useState("");
  const [droplatlng, setdroplatlng] = useState("");
  const [catdata, setcatdata] = useState(null);
  const [isRound, setIsRound] = useState(false);
  const [check, setcheck] = useState("");
  const [selectedVehicle,setSelectedVehicle] = useState()
  const [vehicleKey,setvehicleKey] = useState()



  const [coordinate, setcoordinate] = useState({
    lat: "",
    lng: "",
  });
  const [coord, setcoord] = useState({
    lat: "",
    lng: "",
  });

  const selectedTime = time.$d
    ? moment(time.$d).seconds(0).milliseconds(0)
    : moment(time).seconds(0).milliseconds(0);
  const currentTime = moment().seconds(0).milliseconds(0);
  const [select, setselect] = useState("");

  const handleDateAndTime = (e) => {
    setselect(e.$d);
    settime(e.$d);
  };
  const handleClearDropSelection = () => {
    setDropValue("");
    setdrop("");
  };

  const handleDrop = async (value) => {
    const result = await geocodeByAddress(value);
    setdroplatlng(result[0].formatted_address);
    const l2 = await getLatLng(result[0]);
    setcoord(l2);
    setDropValue(result[0].formatted_address);
    setdrop(value);
  };

  useEffect(() => {
    const handleDropOutsideClick = (event) => {
      if (
        dropAutocompleteRef?.current &&
        !dropAutocompleteRef?.current?.event?.target?.value &&
        !dropValue
      ) {
        setdrop("");
      }
    };

    document.addEventListener("mousedown", handleDropOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleDropOutsideClick);
    };
  }, [drop, dropValue]);

  const onChangedrop = (e) => {
    setdrop(e);
    setDropValue("");
  };
  const handleClearSelection = () => {
    setAddressValue("");
    setaddress("");
  };

  const handleSelect = async (value) => {
    const result = await geocodeByAddress(value);
    setPickupAddress(result[0].formatted_address);
    const l1 = await getLatLng(result[0]);
    setcoordinate(l1);
    setAddressValue(result[0].formatted_address);
    setaddress(value);
  };

  const onChangeAddress = (e) => {
    setaddress(e);
    setAddressValue("");
  };
  useEffect(() => {
    handleCat();
  }, []);

  const handleCat = () => {
    fetch(`${process.env.REACT_APP_URL}/categoryapi/getcategoriesv1`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setcatdata(data.response);
      });
  };

  const chooseVehicle =(item,i)=>{
    setvehicleKey(i)
    setSelectedVehicle(item.cat_name)
    
  }

  return (
    <div>
      <form className="booking-frm" id="User-Form">
       

        <div className="col-md-12 col-sm-12">
          <strong style={{ marginBottom: "-15px" }}>From</strong>
          <div className="field-holder border padding-input">
            <PlacesAutocomplete
              value={address}
              onChange={onChangeAddress}
              onSelect={handleSelect}
              ref={autocompleteRef}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div>
                  <span className="fas fa-map-marker-alt"></span>
                  <input
                    {...getInputProps({
                      placeholder: "Pickup Location",
                      className: "location-search-input",
                    })}
                    readOnly={addressValue}
                  />
                  {addressValue && (
                    <span
                      className="fas fa-times-circle"
                      style={{
                        marginRight: "20px",
                        fontSize: "17px",
                        cursor: "pointer",
                      }}
                      onClick={handleClearSelection}
                    ></span>
                  )}

                  <div className="autocomplete-dropdown-container">
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item";
                      const style = suggestion.active
                        ? {
                            backgroundColor: "#fafafa",
                            cursor: "pointer",
                          }
                        : {
                            backgroundColor: "#ffffff",
                            cursor: "pointer",
                          };
                      return (
                        <div
                          className="input-suggestion"
                          {...getSuggestionItemProps(suggestion, {
                            style,
                          })}
                        >
                          <i class="material-icons">location_on</i>{" "}
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </div>
        </div>

        <div className="col-md-12 col-sm-12">
          <strong style={{ marginBottom: "-15px" }}>To :</strong>
          <div className="field-holder border">
            <PlacesAutocomplete
              value={drop}
              onChange={onChangedrop}
              onSelect={handleDrop}
              ref={dropAutocompleteRef}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div>
                  <span className="fas fa-map-marker-alt"></span>
                  <input
                    {...getInputProps({
                      placeholder: "Drop Location",
                      className: "location-search-input",
                    })}
                    readOnly={dropValue}
                  />
                  {dropValue && (
                    <span
                      className="fas fa-times-circle"
                      style={{
                        marginRight: "20px",
                        fontSize: "17px",
                        cursor: "pointer",
                      }}
                      onClick={handleClearDropSelection}
                    ></span>
                  )}
                  <div className="autocomplete-dropdown-container">
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item";
                      const style = suggestion.active
                        ? {
                            backgroundColor: "#fafafa",
                            cursor: "pointer",
                          }
                        : {
                            backgroundColor: "#ffffff",
                            cursor: "pointer",
                          };
                      return (
                        <div
                          className="input-suggestion"
                          {...getSuggestionItemProps(suggestion, {
                            style,
                          })}
                        >
                          <i class="material-icons">location_on</i>{" "}
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </div>
        </div>

      
        <div className="col-md-12 col-sm-12 ">
          {/* <strong>Choose an Option:</strong> */}
          <div>
            <label style={{marginRight:70,marginTop:10,marginBottom:"-10px"}}  className="radiostyle">
                <strong>
              <input
                type="radio"
                name="option"
                value="true"
                checked={select === "true"}
                onChange={() => {setselect("true"); setIsRound(false)}}
                className="custom-radio custom-radio:checked" 
                />
              One Way
                </strong>
            </label>
            <label >
            <strong>


              <input
                type="radio"
                name="option"
                value="false"
                checked={select === "false"}
                onChange={() =>{ setselect("false"); setIsRound(true)}}
                className="custom-radio custom-radio:checked" 
                />
              Round Trip 
                </strong>
            </label>
          </div>
        </div>


        <div className="col-md-12 col-sm-12">
          <strong style={{ marginBottom: 0 }}>Start Date</strong>
          <div className="field-holder">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                value={time}
                disablePast
                ampm
                onChange={(e) => handleDateAndTime(e)}
                slotProps={{
                  textField: {
                    error: false,
                    inputProps: { readOnly: true },
                  },
                }}
                format="DD/MM/YYYY hh:mm A"
              />
            </LocalizationProvider>
          </div>
        </div>

        {isRound? (
        <div className="col-md-12 col-sm-12">
          <strong style={{ marginBottom: 0 }}>End Date</strong>
          <div className="field-holder">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                // value={time}
                disablePast
                ampm
                // onChange={(e) => handleDateAndTime(e)}
                slotProps={{
                  textField: {
                    error: false,
                    inputProps: { readOnly: true },
                  },
                }}
                format="DD/MM/YYYY hh:mm A"
              />
            </LocalizationProvider>
          </div>
        </div>):''}


        <div className="col-md-12 col-sm-12" style={{ marginTop: "5px" }}>
          <strong style={{ marginBottom: '2px' }}>Category</strong>

          {/* <div className="pckborder"> */}
          <div style={{ display: "flex" }}>
            {catdata ? (
              <>
                {catdata.map((item, i) => {
                  return (
                    <div key={i}>
                 <div className={`catborder  ${vehicleKey == i ? 'selectvehicle' : ''}` }    onClick={() => chooseVehicle(item,i)}  >

                      <div className="fleet-name">{catdata[i].cat_name}</div>
                      <img src={catdata[i].cat_icon_path} alt={"Oops"} className="catimg" />
                    </div>
                  </div>
                  );
                })}

              </>
            ) : (
              ""
            )}
          </div>
        </div>



        {/* <div className="col-md-12 col-sm-12" style={{ marginTop: "20px" }}>
          <strong className="mt-5">
            Apply coupon
          </strong>
        </div> */}
        <div className="col-md-12 col-sm-12">
                          <p className="ride-terms">
                            I understand and agree with the{" "}
                            <a
                              style={{ cursor: "pointer" }}
                              href="https://www.appicial.com/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Terms
                            </a>{" "}
                            of Service and Cancellation{" "}
                          </p>
                          <label for="book_terms">
                            <input
                              name="book_terms"
                              id="book_terms"
                              value={check}
                              type="checkbox"
                              onChange={(e) => setcheck(e.target.checked)}
                            />
                            <input
                              type="hidden"
                              id="trip_distance"
                              name="distance"
                            />
                            {/* {err && check == false ? (
                              <label
                                id="u_email-error"
                                className="error"
                                style={{
                                  color: "#e74c3c",
                                  fontWeight: "500",
                                  fontSize: "15px",
                                }}
                              >
                                Please accept terms and condition
                              </label>
                            ) : null} */}
                          {/* <div className="col-md-12 col-sm-12"> */}
                        
                            {/* </div> */}
                          </label>
                          <b className="text formText">
                Your chauffeur will wait 25 minutes free of charge
              </b>
                        </div>


        <div className="col-md-12 col-sm-12" >
                          <button className="book-btn">
                            Book Now{" "}
                            <i
                              className="fa fa-arrow-circle-rNclassNameight"
                              aria-hidden="true"
                            ></i>
                          </button>
                        </div>
      </form>
    </div>
  );
};

export default InterCity;
