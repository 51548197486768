import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { loadStripe } from "@stripe/stripe-js";
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const CheckoutForm = (payAmount) => {
  const stripe = useStripe();
  const elements = useElements();
  const [clientSecret, setClientSecret] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const location = useLocation();
  // const payableAmount = location.state.amount * 100;
  const payableAmount = payAmount.payableAmount;

  const navigate = useNavigate();


  useEffect(() => {

  let trip_Data = sessionStorage.getItem("tripData");
  if (!trip_Data) {
    navigate("/booknow");
  }
}, []);


  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_URL}/tronsactionapi/createpaymentintent`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ amount: payableAmount }),
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then((data) => setClientSecret(data.response))
      .catch((error) => {
        console.log(error);
        
      });
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (elements == null) {
      return;
    }

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      // Show error to your customer
      setErrorMessage(submitError.message);
      return;
    }

    // Create the PaymentIntent and obtain clientSecret from your server endpoint

    //  fetch('/create-intent', {
    //   method: 'POST',
    // });

    // const {client_secret: clientSecret} = await res.json();

    const error = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      clientSecret,
      confirmParams: {
        return_url: `${process.env.REACT_APP_BASE_URL}/booknow?rider_amt=${
          payableAmount
        }`,
      },
    });

    // paypal payment
    // const error = await stripe.confirmPayPalPayment(clientSecret, {
    //   // payment_method: '{PAYMENT_METHOD_ID}',
    //   // Return URL where the customer should be redirected after
    //   // the authorization.
    //   return_url: `${process.env.REACT_APP_BASE_URL}/booknow?rider_amt=${
    //     payableAmount
    //   }`,
    // }).then(function(result) {
    //   if (result.error) {
    //     // Inform the customer that there was an error.
    //     console.log("Paypal Error:",result.error);
    //   }
    // });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)l
      setErrorMessage(error.error.message)
      toast.error(error.error.message,"error");
    } else {

      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <>
      <div>
        <form onSubmit={handleSubmit} className="paymentFormHead" style={{}}>
          Payment Information
          <PaymentElement />
          <button
            type="submit"
            disabled={!stripe || !elements}
            className="paymentBtn"
            
          >
            Pay €{payableAmount}.00
          </button>
          {/* Show error message to your customers */}
          {/* {errorMessage && <div style={{fontSize:"15px", color:"red"}}>{errorMessage}</div>} */}
        </form>
      </div>
    </>
  );
};
export default CheckoutForm;
