



import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useLocation } from "react-router-dom";

import CheckoutForm from "./CheckoutForm";

const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_kEY);


const TripPayment = (payAmount) => {

  const location = useLocation();
  // const payableAmount =(  location.state.amount )* 100;
  let payableAmount = payAmount.payAmount;
  let amount = payableAmount*100
  const options = {
    mode: 'payment',
    amount:amount,
    currency: 'eur',

    appearance: {
    
    },}
  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm payableAmount={payableAmount}/>
    </Elements>
  );
};

export default TripPayment;

