import * as Yup from "yup";

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
let regex= /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const RegisterSchema=Yup.object().shape({
    first_name:Yup.string().required("This field is required"),
    last_name:Yup.string().required("This field is required"),
    email:Yup.string().matches(regex,{message:"Please enter a valid email"}).required("This field is required"),
   // phone_no:Yup.string().min(9,"Phone number must be atleast 9 character").max(12,"Must be 12 Number or less").required("Required"),
   phone_no :Yup.string().required("This field is required").matches(/[0-9]/g, "Must be only digits").min(9,"Phone number must be atleast 9 digits"),
//    password: Yup
//     .string()
//     .min(6,"Password must be atleast 6 characters")
//     .matches(passwordRules, { message: "Password must be a combination of uppercase letters, lowercase letters, numbers, special characters." })
//     .required("This field is required"),
     country:Yup.string().required("This field is required"),
    acceptedTos: Yup
    .boolean()
    .oneOf([true], "Please select terms and conditions"),
    // gender:Yup.string().oneOf(["Male","Female"],"This field is required").required("This field is required"),
    //referral:Yup.string().required("This field is required")
})

export const forgrtSchema=Yup.object().shape({
    forgetPassword:Yup.string().matches(regex,{message:"Please enter a valid email"}).required("This field is required")
})

export const contactSchema=Yup.object().shape({
    full_name:Yup.string().min(3,"Name must be atleast 3 character").required("This field is required"),
    email:Yup.string().matches(regex,{message:"Please enter a valid email"}).required("This field is required"),
    subject:Yup.string().min(3,"Subject must be atleast 3 character").required("This field is required")
})

export const loginWithPhoneSchema=Yup.object().shape({
    //country:Yup.string().required("Required"),
    phone_no :Yup.string().required("This field is required").min(9,"Phone number must be atleast 9 digits"),
})