import React,{useEffect} from "react";
import { useNavigate } from "react-router-dom";


function PaymentFailedPage() {
  const navigate = useNavigate()

  useEffect(()=>{
      setTimeout(()=>{
          localStorage.setItem("tabValue", "bookinghistory");
          navigate("/booknow");

      },3000)
  },[])
  return (
    <div className="failed-container">
      <div className="failed-page">
        {/* <div className="failed-icon"></div> */}
        <h1 className="failed-title">Payment Failed</h1>
        <p>Your payment could not be processed.</p>
        <p>Please check your payment information and try again.</p>
        {/* You can add more details, buttons, or links here */}
      </div>
    </div>
  );
}

export default PaymentFailedPage;
