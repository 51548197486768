import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

function SuccessfulPaymentPage({setOpenPaySuccessModal}) {
  const navigate = useNavigate();
  const location = useLocation();
  const tripData = sessionStorage.getItem("tripData");
  const apikey = sessionStorage.getItem("key");
  const userid = sessionStorage.getItem("userid");

  const searchParams = new URLSearchParams(location.search);

  const paymentStatus = searchParams.get("redirect_status");
  const payable_amt = searchParams.get("rider_amt");
  const payment_intent = searchParams.get("payment_intent");
  const client_secret = searchParams.get("client_secret");
  const res_data = searchParams.get("resData");

  console.log("Success payment page::::",paymentStatus,payable_amt,payment_intent,client_secret);
  
  useEffect(() => {
    let trip_Data = sessionStorage.getItem("tripData");
    if (!trip_Data) {
      navigate("/booknow");
    }
  }, []);

  const addTransaction = (tripSaveResponse) => {
    let addTripData = {
      trip_id: tripSaveResponse.trip_id,
      pay_response: res_data ? res_data : {
        object: "payment_intent",
        id: payment_intent,
        amount: payable_amt,
      },
      client_secret: client_secret,
      pay_mode: "Card",
      d_curr: "€",
      rider_amt: payable_amt,
      api_key: apikey,
      user_id: userid,
      r_curr: "€",
      currency: "eur",
      trans_desc: "Trip Payment",
      curr_multiplier: 1.0,
      exc_dt: {
        u_pg_currency: "EUR",
        u_currency: "€",
        u_city_id: process.env.REACT_APP_CITY_ID,
        pg_currency: "EUR",
        city_id: process.env.REACT_APP_CITY_ID,
        currency: "€",
        exchange_rate: 1,
        rider_amt: payable_amt,
      },
    };

    fetch(
      `${process.env.REACT_APP_URL}/tronsactionapi/addtriptrans?api_key=${apikey}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(addTripData),
      }
    ).then((res) => {
      res.json().then((result) => {
        if (res.status == 200) {
          // toast.success("Trip added");
          toast.success("Your request has been saved successfully,");
          sessionStorage.removeItem('tripData');
          localStorage.setItem("tabValue", "bookinghistory");
          setTimeout(()=>{
            setOpenPaySuccessModal(false)
            navigate("/profile");
          },3000)
        } else {
          toast.error(result.message);
        }
      });
    });
  };

  useEffect(() => {


    if (paymentStatus == "succeeded") {
      fetch(`${process.env.REACT_APP_URL}/tripapi/save?api_key=${apikey}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: tripData,
      }).then((res) => {
        res.json().then((result) => {
          if (res.status == 200) {
            // toast.success("Your request has been saved successfully,");
        
            addTransaction(result.response);
     
          } else {
            // alert("hii-1")
            // toast.error(result.message);
            console.log(result.message);
          }
        });
      });
    }
  }, []);
  return (
    <div className="success-containe">
      <div className="success-page">
        {" "}
        {/* Apply a CSS class to the container */}
        {/* <div className="success-icon"> &#x2713; </div> */}
        <h1 className="success-title">Payment Successful</h1>
        <p>Your payment has been processed successfully.</p>
        <p>Thank you for your Booking!</p>
        {/* You can add more details, buttons, or links here */}
      </div>
    </div>
  );
}

export default SuccessfulPaymentPage;
