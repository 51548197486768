import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import Modal from "react-modal";
import "./booking.css";
import { CSVLink } from "react-csv";
import ReactToPrint from "react-to-print";
import DataTable from "react-data-table-component";

const UserWallet = () => {
  const [openmodal, setopenmodal] = useState(false);
  const [addmoney, setaddmoney] = useState("");
  const [error, seterror] = useState(false);
  const apikey = sessionStorage.getItem("key");
  const userid = sessionStorage.getItem("userid");
  const [showtable, setshowtable] = useState([]);
  const [Nan, setNan] = useState(false);
  const [search, setsearch] = useState("");
  const [msg, setmsg] = useState(false);

  const componentRef = useRef();

  const handleaddmoney = () => {
    if (validation()) {
      let data = {
        total_amt: addmoney,
        rider_amt: addmoney,
        trans_description: "ff",
        city_id: process.env.REACT_APP_CITY_ID,
        r_curr: "INR",
        curr_multiplier: "1",
      };
      fetch(
        `${process.env.REACT_APP_URL}/tronsactionapi/addtranswithouttrip?api_key=${apikey}&user_id=${userid}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      ).then((res) => {
        res.json().then((result) => {
          if (res.status == 200) {
            setopenmodal(false);
            setmsg(true);
            setaddmoney("");
            seterror(false);
            amountTable();
          }
        });
      });
    }
  };

  useEffect(() => {
    amountTable();
  }, []);

  const amountTable = () => {
    fetch(
      `${process.env.REACT_APP_URL}/tronsactionapi/gettransactions?api_key=${apikey}&user_id=${userid}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        setshowtable(result.response);
      });
  };

  const validation = () => {
    let result = true;
    if (isNaN(addmoney)) {
      result = false;
      setNan(true);
      seterror(false);
    }
    if (addmoney.length === 0) {
      result = false;
      seterror(true);
      setNan(false);
    }
    return result;
  };

  const headers = [
    { label: "Id", key: "transaction_id" },
    { label: "Date", key: "date" },
    { label: "Type", key: "trans_type" },
    { label: "Amount", key: "total_amt" },
    { label: "Current Balance", key: "r_cur_bal" },
  ];

  let csvData = showtable.map((it, ind) => {
    it.date = moment(it.created).format("YYYY-MM-DD");
    return it;
  });

  const popmodalopen = () => {
    setopenmodal(true);
    setmsg(false);
  };

  const handleCloseMoney = () => {
    setopenmodal(false);
    setNan(false);
    seterror(false);
    setaddmoney("");
  };
  const [filter, setfilter] = useState(csvData);

  const handleFilter = (event) => {
    setsearch(event.target.value);
    const newData = csvData.filter((row) => {
      return row.TransUsr[0].transaction_id.includes(event.target.value);
    });
    setfilter(newData);
  };

  const columns = [
    {
      name: "Id",
      selector: (row) => row.transaction_id,
    },
    {
      name: "Date",
      selector: (row) => row.date,
    },
    {
      name: "Type",
      selector: (row) => row.trans_type,
    },
    {
      name: "Amount",
      selector: (row) => row.total_amt,
    },
    {
      name: "Current Balance",
      selector: (row) => row.r_cur_bal,
    },
  ];

  return (
    <>
      <div class="tab-content">
        {msg ? (
          <div className="alert alert-success">Successfully Added</div>
        ) : null}
        <div
          class="tab-pane active"
          id="confirm_booking"
          style={{ display: "flex", justifyContent: "end" }}
        >
          {/* <button onClick={popmodalopen} className="btn btn-primary">Add Money</button> */}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "10px",
          }}
        >
          <div>
            <CSVLink
              filename={"user-wallet.csv"}
              data={csvData}
              headers={headers}
            >
              <button className="btn btn-primary">CSV</button>
            </CSVLink>
            {/* <ReactToPrint
              trigger={() => (
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  Print
                </button>
              )}
              content={() => componentRef.current}
              pageStyle="print"
            /> */}
          </div>
          <div>
            {/* <button onClick={popmodalopen} className="btn btn-primary">Add Money</button> */}
          </div>
          {/* <div>
                        <input type="text" placeholder="search" value={search} onChange={handleFilter} />
                    </div> */}
        </div>

        <div ref={componentRef} style={{ marginTop: "20px" }}>
          {/* {filter.length==0?<DataTable columns={columns} data={csvData} pagination fixedHeader/>
                    :
                    <DataTable columns={columns} data={filter} pagination fixedHeader/>
                    } */}
          <DataTable columns={columns} data={csvData} pagination fixedHeader />
        </div>
      </div>

      <Modal
        isOpen={openmodal}
        onRequestClose={() => setopenmodal(false)}
        shouldCloseOnOverlayClick={true}
        style={{
          overlay: {
            width: 550,
            height: 360,
            borderRadius: "10px",
            backgroundColor: "none",
            zIndex: "1000",
            top: "50%",
            left: "50%",
            marginTop: "-300px",
            marginLeft: "-350px",
          },
        }}
      >
        <div className="modal-header">
          <button className="close" onClick={handleCloseMoney}>
            ×
          </button>
          <h4 className="modal-title">Add Money</h4>
        </div>
        <div>
          <div className="modal-body">
            <div className="md-form">
              <label>Total Amount</label>
              <input
                type="text"
                name="add_money"
                value={addmoney.replace(/\D/g, "")}
                onChange={(e) => setaddmoney(e.target.value)}
                className="md-textarea form-control"
                id="form"
                min="0"
                required
              />
              {Nan ? (
                <label
                  className="error"
                  id="form-error"
                  style={{ color: "#e74c3c" }}
                >
                  Enter only numeric value
                </label>
              ) : null}
              {error && addmoney.length <= 0 ? (
                <label
                  className="error"
                  id="form-error"
                  style={{ color: "#e74c3c" }}
                >
                  This field is required
                </label>
              ) : null}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-dafault"
              onClick={handleCloseMoney}
            >
              Close
            </button>
            <button className="btn btn-primary" onClick={handleaddmoney}>
              Add Money
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default UserWallet;
