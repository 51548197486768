import React, { useEffect, useState, useRef } from "react";
import { CSVLink } from "react-csv";
import Modal from "react-modal";
import "./booking.css";
import moment from "moment";
import ReactToPrint from "react-to-print";
import DataTable from "react-data-table-component";
import { fontSize } from "@mui/system";

const BookingHistory = () => {
  const statusValues = {
    request: "Request",
    assigned: "Partner Assigned",
    accept: "Partner Accepted",
    arrive: "Partner Arrived",
    begin: "Begin",
    completed: "Completed",
    cancel: "Cancelled",
    p_cancel_pickup: "Partner Cancel at Pickup Location",
    p_cancel_drop: "Partner Cancel at Drop Location",
    expired: "Expired",
    paid_cancel: "Cancelled",
  };

  const csvstatus = { Request: "request" };

  const apikey = sessionStorage.getItem("key");
  const userid = sessionStorage.getItem("userid");
  const [bookingtable, setbookingtable] = useState([]);
  const [openmodal, setopenmodal] = useState(false);
  const [ide, setide] = useState("");
  const [msg, setmsg] = useState(false);
  const [reason, setreason] = useState("");
  const [search, setsearch] = useState("");

  const componentRef = useRef();

  useEffect(() => {
    historytable();
  }, []);

  const HandleCancleTrip = (e) => {
    e.preventDefault();
    let data = {
      trip_id: ide,
      trip_status: "cancel",
      is_return_details: "1",
      trip_reason: reason,
    };
    fetch(`${process.env.REACT_APP_URL}/tripapi/updatetrip?api_key=${apikey}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => {
      res.json().then((data) => {
        if (res.status == 200) {
          setopenmodal(false);
          setmsg(true);
          setreason("");
          historytable();
        }
      });
    });
  };

  const headers = [
    { label: "ID", key: "trip_id" },
    { label: "Date", key: "date" },
    { label: "Time", key: "timefor" },
    { label: "Pickup", key: "trip_from_loc" },
    { label: "DropOff", key: "trip_to_loc" },
    { label: "Total Fare", key: "trip_pay_amount" },
    { label: "Status", key: "status" },
    { label: "Remarks", key: "trip_reason" },
  ];

  const historytable = async () => {
    await fetch(
      `${process.env.REACT_APP_URL}/tripapi/gettrips?api_key=${apikey}&user_id=${userid}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if(data?.response){
          setbookingtable(data?.response);
        }
      }).catch((err)=>{
        console.error("Error:",err);
      });
  };

  let csvData = bookingtable?.map((it, ind) => {
    var e = new Date(it.trip_date);
    var utc_offset_gmt = e.getTimezoneOffset();
    e.setMinutes(e.getMinutes() - utc_offset_gmt);
        it.date = moment(e).format("YYYY-MM-DD");
    it.timefor = moment(e).format("HH:mm");

    // it.date = moment(it.trip_date).format("YYYY-MM-DD");
    // it.timefor = moment(it.trip_date).format("hh:mm A");
    it.status = statusValues[it.trip_status];
    return it;
  });
  const [filter, setfilter] = useState(csvData);

  const handleFilter = (event) => {
    setsearch(event.target.value);
    const newData = csvData.filter((row) => {
      return row.trip_from_loc
        .toLowerCase()
        .includes(event.target.value.toLowerCase());
    });
    setfilter(newData);
  };
  const handleModal = (id) => {
    setopenmodal(true);
    setide(id);
    setmsg(false);
  };

  // const columns = [
  //     {
  //         name: "ID",
  //         selector: (row) => row.trip_id
  //     },
  //     {
  //         name: "Date",
  //         selector: (row) => row.date
  //     },
  //     {
  //         name: "Time",
  //         selector: (row) => row.timefor
  //     },
  //     {
  //         name: "Pickup",
  //         selector: (row) => row.actual_from_loc==null?row.trip_from_loc:row.actual_from_loc
  //     },
  //     {
  //         name: "DropOff",
  //         selector: (row) => row.actual_to_loc==null?row.trip_to_loc:row.actual_to_loc
  //     },
  //     {
  //         name: "Status",
  //         selector: (row) => statusValues[row.trip_status]
  //     },
  //     {
  //         name:"Total Fare",
  //         selector:(row)=>row.trip_pay_amount
  //     },
  //     {
  //         name: "",
  //         selector: (row) => row.trip_status === "request" ? <input id="mybtn" data-toggle="modal" data-target="#myModal372"
  //             className="btn btn-danger" type="submit" onClick={(e) => handleModal(row.trip_id)} value="Cancel"
  //         /> : ""
  //     },
  //     {
  //         name: "Remarks",
  //         selector: (row) => row.trip_reason
  //     }
  // ]
  const columns = [
    {
        name: "Trip#",
      selector: (row) =>(   <div  className="text-center">
      <div>#{ row.trip_id}</div>
      {/* <div>{row.timefor}</div> */}
    </div>)
      //  row.trip_id
      ,
      width: "80px",
    },
    {
      name: "Date",
      selector: (row) => (
        <div  className="text-center">
          <div style={{paddingBottom:5,}}>{row.date}</div>
          <div>{row.timefor}</div>
        </div>
      ),
      width: "120px",
    },
    {
      name: "Locations",
      selector: (row) => {
        const pickup =
          row.actual_from_loc == null ? row.trip_from_loc : row.actual_from_loc;
        const dropOff =
          row.actual_to_loc == null ? row.trip_to_loc : row.actual_to_loc;
        return (
          <div style={{ display: "flex !imporant", flexDirection: "column !imporant" }} >
            <div style={{ marginBottom: "10px", marginTop: "10px" , whiteSpace: "pre-line !imporant"}}>
              <strong   style={{color:"darkgreen"}}>Pickup:</strong> {pickup}
            </div>
            <div style={{ paddingLeft: 0, marginBottom: "10px" }}>
              {" "}
              {dropOff ? (
                <div style={{ whiteSpace: "pre-line !imporant"}}>
                  <strong style={{color:"darkred"}}>DropOff:</strong> {dropOff}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        );
      },
      // width: "500px"  ,
      // height:"200px",
    },
    {
      name: "Status/Remarks",
      selector: (row) => (
        <div className="text-center"  > 
          <div
            style={{ marginTop: "10px",whiteSpace: "pre-line" ,color: statusValues[row.trip_status] == "Completed" ? "green" : "darkred" }}
          >
            {" "}
            {statusValues[row.trip_status]}{" "}
          </div>
          {row.trip_reason && <div style={{whiteSpace: "pre-line" , marginTop:"10px"}}><strong >Remarks:</strong> <div style={{ whiteSpace: "pre-line"}}>{row.trip_reason}</div></div>}
          {row.trip_status === "request" && (
            <input
              id="mybtn"
              data-toggle="modal"
              data-target="#myModal372"
              className="cancelBtn btn-danger btn"
              type="submit"
              onClick={(e) => handleModal(row.trip_id)}
              value="Cancel"
            />
          )}
        </div>
      ),
      maxWidth: "150px",
    },
    {
      name: "Fare",
      selector: (row) => {
        return <div className="text-center">   
            <strong >€{row.trip_pay_amount}</strong>
            </div>
      },
      width: "100px",
      
    },

  ];

  return (
    <>
      <div class="tab-content"  >
        {msg ? (
          <div className="alert alert-success">Trip Cancelled Successfully</div>
        ) : null}
        <div
          class="tab-pane active"
          id="confirm_booking"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div>
            <CSVLink
              filename={"booking-history.csv"}
              data={csvData}
              headers={headers}
            >
              <button className="btn btn-primary">CSV</button>
            </CSVLink>
            {/* <ReactToPrint
              trigger={() => (
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "5px" }}
                >
                  Print
                </button>
              )}
              content={() => componentRef.current}
              pageStyle="print"
            /> */}
          </div>
          {/* <div>
                        <input type="text" placeholder="search" value={search} onChange={handleFilter} />
                    </div> */}
        </div>

        <div
          ref={componentRef}
          style={{ marginTop: "20px", background: "white" }}
        >
          {filter.length == 0 ? (
            <DataTable
              columns={columns}
              data={csvData}
              pagination
              fixedHeader
            />
          ) : (
            <DataTable
              className="my-custom-datatable"
              columns={columns}
              data={filter}
              pagination
              fixedHeader
            />
          )}
        </div>
      </div>

      <Modal
        isOpen={openmodal}
        onRequestClose={() => setopenmodal(false)}
        style={{
          overlay: {
            zIndex: 9,
            backgroundColor:"#00000063",
            position: "fixed",
          },
          content: {
            width: "40%",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%,-50%)",
          },

          
        }}
        shouldCloseOnOverlayClick={true}
        // style={{
        //   overlay: {
        //     width: 650,
        //     height: 400,
        //     borderRadius: "10px",
        //     backgroundColor: "black",
        //     zIndex: "1000",
        //     top: "50%",
        //     left: "50%",
        //     marginTop: "-300px",
        //     marginLeft: "-350px",
        //   },
        // }}
      >
        <div className="modal-header">
          <button className="close" onClick={() => setopenmodal(false)}>
            ×
          </button>
          <h4 className="modal-title">Are You Sure?</h4>
        </div>
        <div className="modal-body">
          <div className="md-form">
            <i className="fas fa-pencil prefix grey-text"></i>
            <label
              data-error="wrong"
              name="cancel_reason"
              data-success="right"
              value
              for="form371</label>"
            >
              Reason For Cancel
            </label>
            <textarea
              type="text"
              name="cancel_reason"
              value={reason}
              onChange={(e) => setreason(e.target.value)}
              id="form371"
              className="md-textarea form-control"
              rows="4"
            ></textarea>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-dafault"
            onClick={() => setopenmodal(false)}
          >
            Close
          </button>
          <button
            className="btn btn-primary"
            onClick={(e) => HandleCancleTrip(e)}
          >
            Cancel Trip
          </button>
        </div>
      </Modal>
    </>
  );
};
export default BookingHistory;
