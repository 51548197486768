import React from "react";

const Error=()=>{

    const ErrorStyle={
        fontWeight:"500",
        textAlign:"center",
        marginTop:"20px",
        fontSize:"25px"
    }
    return(
        <>
        <p style={ErrorStyle}>Oops ! page not found</p>
        </>
    )
}
export default Error